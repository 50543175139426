import React from 'react';

const Item = () => <div className="w-20 h-6 bg-gray-200 rounded-full animate-pulse" />;

export default function HomePageAnimation() {
  return (
    <div className="flex flex-col gap-4 w-screen h-screen">
      <div className="w-full rounded-lg h-96 md:h-60 bg-gray-200 animate-pulse" />
      <div className="py-2 md:py-5 px-10 md:px-40 rounded-lg w-full">
        <div className="bg-gray-200 p-5 md:p-10 rounded-lg w-full" />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4 overflow-hidden px-5 md:px-40 lg:px-60">
        {['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'].map((ix) => (
          <div className="flex flex-col p-4 w-full gap-4" key={`${ix}`}>
            <div className="h-20 bg-gray-200 rounded-md animate-pulse" />
            <div className="flex flex-col gap-2">
              <Item />
            </div>
            <div className="flex flex-col gap-2">
              <div className="w-3/4 md:w-full h-4 bg-gray-200 rounded-full animate-pulse" />
              <div className="w-3/4 md:w-full h-4 bg-gray-200 rounded-full animate-pulse" />
              <div className="w-1/2 h-4 bg-gray-100 rounded-full animate-pulse" />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
