import { SEO } from 'components/Seo';
import home from 'components/pages/home';

export default home;
export const Head = () => (
  <SEO>
    <script type="application/ld+json">
      {`
    {
      "@context": "https://schema.org",
      "@type": "Organization",
      "url": "https://www.tutadoo.com",
      "name": "Tutadoo Technologies"
    }
  `}
    </script>
  </SEO>
);
